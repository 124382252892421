.app {
  text-align: center;
}

nav {
  width: 100%;
  max-width: 600px;
  margin: 50px auto;
}

nav ul {
  display: flex;
  align-content: space-between;
  align-items: stretch;
  padding: 0;
}

nav li {
  flex: 1 1 auto;
  list-style: none;
}

nav li a {
  padding: 5px 15px;
  color: #FF427E;
}

nav li .active {
  text-decoration: none;
  background: #FF427E;
  color: #fff;
  border-radius: 14px;
}

main {
  width: 100%;
  margin: 50px 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.player {
  height: 500px;
  max-width: 800px;
  margin: 0 auto;
}

.uploader {
  height: 500px;
  max-width: 800px;
  margin: 0 auto;
}

.feed {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
}

.feed-player {
  position: relative;
  max-width: 800px;
  margin: 50px auto;
  height: 500px;
  margin: 0 auto;
  padding: 0 0 50px;
  box-sizing: border-box;
}
